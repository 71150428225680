import React from 'react'
import { Box } from '@mui/material'
import Header from 'components/Header'

const Dashboard = () => {
  return (
    <Box m="1.5rem 2.5rem">
      <Header title="DASHBOARD" subtitle="Tableau de bord du module dissemination"/>
    </Box>
  )
}

export default Dashboard
import { createSlice } from "@reduxjs/toolkit";

const prefersDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

const initialState = {
    mode: prefersDarkMode ? "dark" : "light",
    userName: "Ndiaga",
};

export const globalSlice = createSlice({
    name: "global",
    initialState,
    reducers: {
        setMode: (state) => {
            state.mode = state.mode === "light" ? "dark" : "light";
        },
    },
});

export const { setMode } = globalSlice.actions;

export default globalSlice.reducer;
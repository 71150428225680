import React from 'react'
import { Card, CardContent, Typography, Button, Box, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert'

const AlertCard = ({ alert }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card sx={{ marginBottom: 2 }}>
      <CardContent>
        <Typography variant="h4" component="div">
          {alert.topic}
        </Typography>
        <Typography variant="h6" color="text.secondary">
          {alert.description}
        </Typography>
        <Typography variant="h6" color="text.secondary">
        <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Langue :</span> {alert.lang}
        </Typography>
        <Typography variant="h6" color="text.secondary">
        <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Durée Globale :</span> {alert.cj_stories_info?.duration || 0} Seconde(s)
        </Typography>
        <Typography variant="h6" color="text.secondary">
        <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Date de Lancement :</span> {alert.created_at}
        </Typography>
        <Typography variant="h6" color="text.secondary">
        <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Participants :</span> {alert.cj_stories_info?.users || 0}
        </Typography>
        <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            color="primary"
            aria-controls="actions-menu"
            aria-haspopup="true"
            onClick={handleClick}
            endIcon={<MoreVertIcon />}
          >
            Actions
          </Button>
          <Menu
            id="actions-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={handleClose}>Voir l'alerte</MenuItem>
            <MenuItem onClick={handleClose}>Éditer l'alerte</MenuItem>
            <MenuItem onClick={handleClose}>Afficher les participants</MenuItem>
            <MenuItem onClick={handleClose}>Copier cette alerte</MenuItem>
            <MenuItem onClick={handleClose}>Fermer l'alerte</MenuItem>
            <MenuItem onClick={handleClose}>Exécuter l'alerte immédiatement</MenuItem>
          </Menu>
        </Box>
      </CardContent>
    </Card>
  );
};

export default AlertCard
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import JOKALANTE from 'assets/JOKALANTE.png'
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import {
  ChevronLeft,
  ChevronRightOutlined,
  //HomeOutlined,
  SettingsOutlined,
  //PhoneInTalkOutlined,
} from "@mui/icons-material";
import AddIcCallOutlinedIcon from '@mui/icons-material/AddIcCallOutlined';
import PhoneForwardedOutlinedIcon from '@mui/icons-material/PhoneForwardedOutlined';
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
// import RecordVoiceOverOutlinedIcon from '@mui/icons-material/RecordVoiceOverOutlined';
// import VoicemailOutlinedIcon from '@mui/icons-material/VoicemailOutlined';
// import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
// import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import FlexBetween from "./FlexBetween";
import profileImage from "assets/profile.jpeg";
  
const navItems = [
  // {
  //   text: "Dashboard",
  //   icon: <HomeOutlined />,
  // },
  {
    text: "Carnet d'adresses",
    icon: null,
  },
  {
    text: "Liste des contacts",
    icon: <PermContactCalendarOutlinedIcon />,
  },
  {
    text: "Nouveau contact",
    icon: <PersonAddAltOutlinedIcon />,
  },
  {
    text: "Alertes",
    icon: null,
  },
  {
    text: "Liste des Alertes",
    icon: <PhoneForwardedOutlinedIcon />,
  },
  {
    text: "Création d'Alerte",
    icon: <AddIcCallOutlinedIcon />,
  },
  {
    text: "Messagerie",
    icon: null,
  },
  // {
  //   text: "Consultation des Messages",
  //   icon: <RecordVoiceOverOutlinedIcon  />,
  // },
  // {
  //   text: "Gestion des Messages",
  //   icon: <VoicemailOutlinedIcon />,
  // },
  {
    text: "IVR",
    icon: null,
  },
  // {
  //   text: "Navigation IVR",
  //   icon: <PhoneInTalkOutlined />,
  // },
  {
    text: "Mailchimp",
    icon: null,
  },
  // {
  //   text: "Envoi d'Email",
  //   icon: <ForwardToInboxOutlinedIcon  />,
  // },
  // {
  //   text: "Archives",
  //   icon: <ArchiveOutlinedIcon  />,
  // },
];  


const Sidebar = ({
    user,
    drawerWidth,
    isSidebarOpen,
    setIsSidebarOpen,
    isNonMobile,
  }) => {
    const { pathname } = useLocation();
    const [active, setActive] = useState("");
    const navigate = useNavigate();
    const theme = useTheme();

    useEffect(() => {
        setActive(pathname.substring(1));
      }, [pathname]);
    
  return (
    <Box component="nav">
      {isSidebarOpen && (
        <Drawer
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          variant="persistent"
          anchor="left"
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              color: theme.palette.secondary[50],
              backgroundColor: theme.palette.background.alt,
              boxSixing: "border-box",
              borderWidth: isNonMobile ? 0 : "2px",
              width: drawerWidth,
            },
          }}
        >
          <Box width="100%">
            <Box m="1.5rem 2rem 2rem 3rem">
              <FlexBetween color={theme.palette.secondary.main}>
                <Box display="flex" alignItems="center" gap="0.5rem">
                  <img src={JOKALANTE} alt="JOKALANTE" height="50px" />
                </Box>
                {!isNonMobile && (
                  <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                    <ChevronLeft />
                  </IconButton>
                )}
              </FlexBetween>
            </Box>
            <List>
              {navItems.map(({ text, icon }) => {
                if (!icon) {
                  return (
                    <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem" }}>
                      {text}
                    </Typography>
                  );
                }
                const lcText = text.toLowerCase();

                return (
                  <ListItem key={text} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        navigate(`/${lcText}`);
                        setActive(lcText);
                      }}
                      sx={{
                        backgroundColor:
                          active === lcText
                            ? theme.palette.secondary[50]
                            : "transparent",
                        color:
                          active === lcText
                            ? theme.palette.primary[600]
                            : theme.palette.secondary[50],
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          ml: "2rem",
                          color:
                            active === lcText
                              ? theme.palette.primary[600]
                              : theme.palette.secondary[50],
                        }}
                      >
                        {icon}
                      </ListItemIcon>
                      <ListItemText primary={text} />
                      {active === lcText && (
                        <ChevronRightOutlined sx={{ ml: "auto" }} />
                      )}
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>
          <Box flexGrow={1} />
          <Box position="sticky" bottom="0" bgcolor="background.paper">
            <Divider />
            <FlexBetween textTransform="none" gap="1rem" m="0.5rem 2rem 0.5rem 3rem">
              <Box
                component="img"
                alt="profile"
                src={profileImage}
                height="40px"
                width="40px"
                borderRadius="50%"
                sx={{ objectFit: "cover" }}
              />
              <Box textAlign="left">
                <Typography
                  fontWeight="bold"
                  fontSize="0.9rem"
                  sx={{ color: theme.palette.secondary[50] }}
                >
                  {user.name}
                </Typography>
                <Typography
                  fontSize="0.8rem"
                  sx={{ color: theme.palette.secondary[50] }}
                >
                  {user.location}
                </Typography>
              </Box>
              <SettingsOutlined
                sx={{
                  color: theme.palette.secondary[50],
                  fontSize: "25px ",
                }}
              />
            </FlexBetween>
          </Box>
        </Drawer>
      )}
    </Box>

  );
};

export default Sidebar
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import Header from 'components/Header';
import { PlayCircleOutline as PlayIcon, 
  PauseCircleOutline as PauseIcon 
} from '@mui/icons-material';
import { 
  Box, 
  TextField, 
  Button, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  Grid, 
  Typography, 
  IconButton, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions 
} from '@mui/material';

const AddAlert = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [alertCycle, setAlertCycle] = useState(1);
  const [lang, setLang] = useState('fr');
  const [audio, setAudio] = useState(null);
  const [audioFile, setAudioFile] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [open, setOpen] = useState(false);

  const handleAlertCycleChange = (event) => {
    setAlertCycle(event.target.value);
  };

  const handleLangChange = (event) => {
    setLang(event.target.value);
  };

  const handleAudioChange = (event) => {
    setAudioFile(URL.createObjectURL(event.target.files[0]));
    setAudio(new Audio(URL.createObjectURL(event.target.files[0])));
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    navigate('/liste des alertes');
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleOpen();
    console.log('POST', {
      title,
      description,
      startDate,
      startTime,
      alertCycle,
      lang,
      audioFile,
    });
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="AJOUTER UNE NOUVELLE ALERTE" subtitle="Créez une nouvelle alerte vocale à diffuser"/>
      <Box mt="1.5rem" />
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField fullWidth label="Titre de l'alerte" variant="outlined" value={title} onChange={e => setTitle(e.target.value)} />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Description de l'alerte" variant="outlined" multiline rows={4} value={description} onChange={e => setDescription(e.target.value)} />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth label="Date de début" type="date" InputLabelProps={{ shrink: true }} variant="outlined" value={startDate} onChange={e => setStartDate(e.target.value)} />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth label="Heure de début" type="time" InputLabelProps={{ shrink: true }} variant="outlined" value={startTime} onChange={e => setStartTime(e.target.value)} />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Répétition de l'audio</InputLabel>
              <Select value={alertCycle} onChange={handleAlertCycleChange}>
                <MenuItem value={1}>Une fois</MenuItem>
                <MenuItem value={2}>Deux fois</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Langue de l'alerte</InputLabel>
              <Select value={lang} onChange={handleLangChange}>
                <MenuItem value="fr">Français</MenuItem>
                <MenuItem value="en">Anglais</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <Button variant="contained" component="label">
                Télécharger l'audio
                <input type="file" hidden accept=".wav" onChange={handleAudioChange} />
              </Button>
              {audioFile && (
                <Box display="flex" alignItems="center" ml={2}>
                  <IconButton onClick={handlePlayPause}>
                    {isPlaying ? <PauseIcon /> : <PlayIcon />}
                  </IconButton>
                  <Typography variant="body1" ml={1}>Prévisualiser l'audio</Typography>
                  <audio src={audioFile} style={{ display: 'none' }} />
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" type="submit">
              Créer une alerte
            </Button>
          </Grid>
        </Grid>
      </form>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{"Nouvelle alerte créée"}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Votre alerte a été créée avec succès !</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default AddAlert;

import React from 'react'
import AlertCard from '../../components/AlertCard'
import { Grid, Box, useMediaQuery } from '@mui/material';
import { LinearProgress } from '@mui/material';
import Header from 'components/Header'
import { useGetAlertsQuery } from 'state/api'
const AlertList = () => {
  const { data, isLoading } = useGetAlertsQuery();
  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  const alerts = data?.content || [];
  return (
    <Box m="1.5rem 2.5rem">
          <Header title="LISTE DES ALERTES" subtitle="Consultez et managez vos alertes"/>
          {data || !isLoading ? (
          <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(3, minmax(0, 1fr))"
          justifyContent="space-between"
          rowGap="20px"
          columnGap="1.33%"
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >
          {alerts.map((alert) => (
            <Grid item xs={12} sm={6} md={4} key={alert.id}>
              <AlertCard alert={alert} />
            </Grid>
          ))}
          </Box>
        ) : (
          <LinearProgress style={{ height: '10px' }} />
        )}
    </Box>
  )
}

export default AlertList
import React from 'react';
import { Box, Grid, CircularProgress } from '@mui/material';
import Header from 'components/Header';
import { useGetUsersQuery } from 'state/api';
import UserCard from 'components/UserCard';

const UserList = () => {
  const { data, isLoading } = useGetUsersQuery();
  const users = data?.content || [];
  return (
    <Box m="1.5rem 2.5rem">
      <Header title="CARNET D'ADRESSES" subtitle="Consultez et managez Votre carnet d'adresses"/>
      <Box mt="1.5rem" />
      {isLoading ? (
        <Box display="flex" justifyContent="center" mt={4}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={4}>
          {users.map((user) => (
            <Grid item xs={12} sm={6} md={4} key={user.id}>
              <UserCard user={user} />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default UserList;

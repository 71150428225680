import React from 'react'
import { Box } from '@mui/material'
import Header from 'components/Header'

const AddUser = () => {
  return (
    <Box m = "1.5rem 2.5rem">
        <Header title = "AJOUTER UN CONTACT" subtitle="Ajouter un nouveau contact à son carnet d'adresse" />
    </Box>
  )
}

export default AddUser
import React from 'react';
import { Card, CardContent, Typography, Avatar, Grid } from '@mui/material';
import { pink, blue } from '@mui/material/colors';
import { styled } from '@mui/system';

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  borderRadius: '10px',
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  background: props => {
    console.log('Gender:', props.gender);
    return props.gender === 1 ? `linear-gradient(135deg, ${blue[500]} 0%, ${blue[700]} 100%)` : `linear-gradient(135deg, ${pink[500]} 0%, ${pink[700]} 100%)`
  },
  color: '#fff',
  marginRight: theme.spacing(2),
  marginLeft: theme.spacing(2),
  width: theme.spacing(7),
  height: theme.spacing(7),
  fontSize: theme.spacing(4),
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginRight: theme.spacing(1),
  display: 'inline',
}));

const UserCard = ({ user }) => {
  const getDisplayValue = (value) => value || 'Inconnu';  
  return (
    <StyledCard>
      <Grid container alignItems="center">
        <Grid item>
          <StyledAvatar gender={user.gender}></StyledAvatar>
        </Grid>
        <Grid item>
          <CardContent>
            <Typography variant="h5" component="div">
              {user.name}
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <TitleTypography variant="body1" color="text.secondary">
                  Type de profil: 
                </TitleTypography>{user.type === 1 ? 'Personne' : 'Organisation'}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TitleTypography variant="body1" color="text.secondary">
                  Tel:</TitleTypography> +{getDisplayValue(user.phones)}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TitleTypography variant="body1" color="text.secondary">
                  Langues: </TitleTypography>{getDisplayValue(user.languages.join(', '))}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TitleTypography variant="body1" color="text.secondary">
                  Localisation: </TitleTypography>{getDisplayValue(`${user.region}, ${user.department}, ${user.commune}, ${user.town}`)}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TitleTypography variant="body1" color="text.secondary">
                  Profession: </TitleTypography>{getDisplayValue(user.job)}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TitleTypography variant="body1" color="text.secondary">
                  Mode de communication préféré: </TitleTypography>{getDisplayValue(user.communication)}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TitleTypography variant="body1" color="text.secondary">
                  Radio préférée: </TitleTypography>{getDisplayValue(user.radio)}
              </Grid>
            </Grid>
          </CardContent>
        </Grid>
      </Grid>
    </StyledCard>
  );
};

export default UserCard;
